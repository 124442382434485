export function getRandomInt (min, max)  {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

export function isImageVertical(el) {
    return (el.clientWidth/el.clientHeight) > 1;
}

export function getElementPercentageWidth(el) {
    return Math.floor((el.clientWidth  * 100) / window.innerWidth)
}

export function getElementPercentageHeight(el) {
    return Math.floor((el.clientHeight  * 100) / window.innerHeight)
}

export function setImagePosition(elem, top, left, right = null, bottom = null) {
    if(top) {
        elem.style.top = top + 'px';
    }

    if(left) {
        elem.style.left = left + '%';
    }

    if(right) {
        elem.style.left = 'unset';
        elem.style.right = right + '%';
    }
    if(bottom) {
        elem.style.top = 'unset';
        elem.style.bottom = bottom + 'px';
    }
}

export function calculateImagePositions(elements) {
    const images = queryAll(elements)

    if (!images) {
        return
    }

    const img1 = images[0]
    const img2 = images[1]
    const img3 = images[2]

    if (img1 && isImageVertical(img1)) {
        // const topPosition1 =  getRandomInt(0, 100 - getElementPercentageHeight(img1))
        // const leftPosition1 =  getRandomInt(0, 50 - getElementPercentageWidth(img1))

        const topPosition1 =  getRandomInt(25, 175)
        const leftPosition1 =  getRandomInt(1, 7)

        setImagePosition(img1, topPosition1, leftPosition1)

        let topPosition2 = 0
        let img2Height = 0

        if(img2) {
            // topPosition2 =  getRandomInt(0, 100 - getElementPercentageHeight(img2))
            topPosition2 =  getRandomInt(topPosition1, 375)
            const rightPosition2 =  getRandomInt(1, 5)
            img2Height = img2.clientHeight

            setImagePosition(img2, topPosition2, null, rightPosition2)
        }
        if (img3) {
            const leftPosition3 = getRandomInt(15, 90 - getElementPercentageWidth(img3))
            const bottomPosition3 = getRandomInt(10, getElementPercentageHeight(img2))
            setImagePosition(img3, null, leftPosition3, null, bottomPosition3)
        }
    } else {
        //horizontal case
        const topPosition1 =  getRandomInt(10, 250)
        const leftPosition1 =  getRandomInt(3, 10)

        setImagePosition(img1, topPosition1 ,leftPosition1)

        let topPosition2 = 0
        let img2Height = 0
        let rightPosition2 = 0

        if (img2) {
            topPosition2 = getRandomInt(100, 400)
            rightPosition2 =  getRandomInt(1, 5)

            img2Height = img2.clientHeight

            setImagePosition(img2, topPosition2, null, rightPosition2)
        }

        if (img3) {
            const bottomPosition3 = getRandomInt(0, topPosition2 + img2Height)
            const image1Space = parseInt(getElementPercentageWidth(img1)) + parseInt(img1.style.left.split('%')[0])
            const leftPosition3 = getRandomInt(image1Space, 45)
            setImagePosition(img3, null, leftPosition3, null, bottomPosition3)
        }
    }

}

export function isSmallViewport(){
    return window.innerWidth < 1024
}

export function query(selector, context = document) {
    if (typeof selector !== 'string') {
        return selector;
    }

    return context.querySelector(selector);
}

export function queryAll(selector, context = document) {
    if (typeof selector !== 'string') {
        return selector;
    }

    return Array.prototype.slice.call(context.querySelectorAll(selector));
}

export function toggleClass(element, className, condition = null) {
    if (condition === null) {
        if (element.classList.contains(className)) {
            element.classList.remove(className);
        } else {
            element.classList.add(className);
        }
    } else {
        if (condition) {
            element.classList.add(className);
        } else {
            element.classList.remove(className);
        }
    }
}

export function removeClass(nodes, className) {
    if (Array.isArray(nodes)) {
        nodes.forEach(node => node.classList.remove(className));
    } else {
        nodes.classList.remove(className);
    }

    return nodes;
}

export function addClass(nodes, className) {
    if (Array.isArray(nodes)) {
        nodes.forEach(node => node.classList.add(className));
    } else {
        nodes.classList.add(className);
    }

    return nodes;
}

export function triggerEvent(
    element,
    eventType,
    params = null,
    options = {
        bubbles: true,
        cancelable: true,
        detail: null
    }) {
    options.detail = params;
    const event = new CustomEvent(eventType, options);
    element.dispatchEvent(event);
}
